@import "styles/modded-bootstrap";
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.0-1/css/all.min.css');

html, body {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth !important;
}

body {
  min-width: 320px;
  background-color: #ffffff;
}

.mwc {
  width: 100%;
  max-width: 1440px;
  @extend .mx-auto;
  @extend .px-3;
}

a.link-unstyled {
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: inherit;
    text-decoration: inherit;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.text-shadow {
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6);
}

img.inline,
svg.inline {
  height: 1em;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.transaction-list-wrap {
  max-width: 700px;
}
