@import "../../node_modules/bootstrap/scss/mixins/banner";
@include bsBanner("");

$font-family-base: 'Lato', sans-serif;


// scss-docs-start import-stack
// Configuration
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
//@import "~bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
//@import "~bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/accordion";
//@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
//@import "~bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
//@import "~bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/offcanvas";
@import "../../node_modules/bootstrap/scss/placeholders";


// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack


.page-link {
  --bs-pagination-color: #0F123E4A;
  --bs-pagination-bg: transparent;

  --bs-pagination-hover-color: #FFFFFF;
  --bs-pagination-hover-bg: var(--text-muted);

  --bs-pagination-active-bg: #0F123E;
  --bs-pagination-active-border-color: #0F123E;

  --bs-pagination-disabled-bg: #E0E1F2;

  --bs-pagination-border-radius: 100%;
  border-radius: 100%;
  min-width: 31px;

  text-align: center;

  svg > path {
    stroke: var(--bs-pagination-color);
  }

  &:hover {
    svg > path {
      stroke: var(--bs-pagination-hover-color);
    }
  }
}

.popover {
  &.dark {
    border-color: #343a40;

    .popover-header {
      background-color: #343a40;
      color: #f8f9fa;
    }
    .arrow:after {
      border-left-color: #343a40;
    }
  }
}
